import React, { useContext, useState } from "react";
import "./NaviBar.scss";
import { useHistory, useLocation } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import amduongLogo from '../../assets/images/amduongLogo.png'
import { logoutUser } from "../../services/apiService";
import { toast } from "react-toastify";
import { Button, Modal, Form } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { changPassword } from '../../services/apiService'


const NaviBar = (props) => {
    const { user, logoutContext } = useContext(UserContext)
    const location = useLocation()
    const history = useHistory()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState({ email: '', password: '' })
    const [passUpdate, setPassUpdate] = useState('')
    const [confirmPass, setConfirmPass] = useState('')

    const defaultValidInput = {
        isValidPassword: true,
        isValidConfirmPassword: true,
    }
    const [ojbCheckInput, setOjbCheckInput] = useState(defaultValidInput)

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const isValidInputs = () => {
        setOjbCheckInput(defaultValidInput);
        if (!passUpdate) {
            toast.error('Mật khẩu không được trống !')
            setOjbCheckInput({ ...defaultValidInput, isValidPassword: false })
            return false
        }
        if (!confirmPass) {
            toast.error('Mật khẩu không được trống !')
            setOjbCheckInput({ ...defaultValidInput, isValidConfirmPassword: false })
            return false
        }
        if (passUpdate !== confirmPass) {
            toast.error('Mật khẩu không giống nhau !')
            setOjbCheckInput({ ...defaultValidInput, isValidConfirmPassword: false })
            return false
        }
        return true
    }

    const handleUpdatePass = async () => {
        let check = isValidInputs()
        if (check === true) {
            setLoading(true)
            console.log('check id user', user.account.email)
            console.log('check id pass update', passUpdate)
            let userEmail = user.account.email
            let res = await changPassword({ ...userData, email: userEmail, password: passUpdate })
            if (res && res.EC === 0) {
                toast.success('Cập nhật mật khẩu thành công !')
                setIsModalOpen(false)
                setLoading(false)
            } else {
                setIsModalOpen(false)
                setLoading(false)
                toast.success(res.EM)
            }
        }
        setConfirmPass('')
        setPassUpdate('')
    }

    const handleLogout = async () => {
        localStorage.removeItem('tokenJWT') // clear jwt local storage
        let data = await logoutUser() // clear cookie
        logoutContext() // clear user data in context
        if (data && data.EC === 0) {
            toast.success('Đăng xuất thành công')
            localStorage.removeItem('activePage')
            history.push('/login')
        } else {
            toast.error(data.EM)
        }
    }

    if ((user && user.isAuthenticated === true) || location.pathname === '/') {
        return (
            <>
                <div className="nav-header">
                    <Navbar className="bg-header" expand="lg">
                        <div>
                            <Navbar.Brand className="nav-brand d-xl-block d-none">
                                <img src={amduongLogo} width="35" height="35"
                                    alt=""
                                    className="d-inline-block align-top" />
                                <span className="logo-text px-3 ">PHÒNG KHÁM ĐÔNG Y - BS ĐOÀN VĂN THANH</span>
                            </Navbar.Brand>
                        </div>
                        <Container className="d-flex justify-content-end">
                            <Nav>
                                {user && user.isAuthenticated === true
                                    ?
                                    <>
                                        <div className="setting">
                                            <Nav.Item className="nav-link d-xl-block d-none">Chào bác sĩ {user.account.username} !</Nav.Item>
                                            <NavDropdown title="Cài đặt" id="basic-nav-dropdown " className="d-xl-block d-none">
                                                <NavDropdown.Item>
                                                    <span onClick={showModal}>Đổi mật khẩu</span>
                                                    <Modal
                                                        width={350}
                                                        title="Cập nhật mật khẩu"
                                                        open={isModalOpen}
                                                        onOk={handleOk}
                                                        onCancel={handleCancel}
                                                        footer={[
                                                            <Button key="back" onClick={handleCancel}>
                                                                Đóng
                                                            </Button>,
                                                            <Button
                                                                key='link'
                                                                type="primary"
                                                                //disabled={fileUpload.length === 0}
                                                                onClick={(e) => handleUpdatePass(e)}
                                                                loading={loading}
                                                            >{loading ? '' : <i className="fa fa-floppy-o" />}</Button>
                                                        ]}
                                                    >
                                                        <div>
                                                            <input
                                                                className={ojbCheckInput.isValidPassword ? 'Input-pass form-control' : 'Input-pass form-control is-invalid'}
                                                                type='password' placeholder='Mật khẩu'
                                                                value={passUpdate} onChange={(event) => setPassUpdate(event.target.value)}
                                                            />
                                                            <input
                                                                style={{ marginTop: '10px' }}
                                                                className={ojbCheckInput.isValidConfirmPassword ? 'Input-pass form-control' : 'Input-pass form-control is-invalid'}
                                                                type='password' placeholder='Nhập lại mật khẩu'
                                                                value={confirmPass} onChange={(event) => setConfirmPass(event.target.value)}
                                                            />
                                                        </div>
                                                    </Modal>
                                                </NavDropdown.Item>
                                                <NavDropdown.Divider />
                                                <NavDropdown.Item>
                                                    <span onClick={() => handleLogout()}>Đăng xuất</span>
                                                </NavDropdown.Item>
                                            </NavDropdown>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {localStorage.removeItem('activePage')}
                                        {history.push('/login')}
                                    </>
                                }
                            </Nav>
                        </Container>
                    </Navbar>
                </div>
            </>
        )
    } else {
        return <></>
    }
}

export default NaviBar;