import axios from '../setup/axios'

const uploadImage = async (base64EncodedImage) => {
    try {
        let res = await fetch(`/api/v1/project/upload-image`, {
            method: 'POST',
            body: JSON.stringify({ data: base64EncodedImage }),
            headers: { 'Content-Type': 'application/json' },
        });
        const resImage = await res.json()
        return resImage
    } catch (err) {
        console.error('Có lỗi khi up hình imageService : ', err);
    }
}

const uploadAvatar = async (dataFile) => {
    return axios.post(`/api/v1/project/upload-avatar`, dataFile,
        { headers: { "Content-Type": "multipart/form-data" } }
    )
}

export {
    uploadAvatar,
    uploadImage
} 