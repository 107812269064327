import './HomeUploadFiles.scss'
import React, { useState } from 'react'
import FormData from 'form-data'
import { fetchAllFileWithPagin, uploadFile, deleteFile, searchFile } from '../../../services/uploadService'
import { toast } from 'react-toastify'
import { Button, Table, Modal, Popover, Upload } from 'antd';
import { useEffect } from 'react'
import dayjs from 'dayjs';
import {
    FilePdfOutlined, FilePptOutlined, PictureOutlined, WindowsOutlined, FileUnknownOutlined,
    FileWordTwoTone, FileSyncOutlined, FileExcelOutlined, FileTextTwoTone, FileZipOutlined,
    CloudDownloadOutlined, SyncOutlined, CloudUploadOutlined, UploadOutlined
} from '@ant-design/icons';
import { Bars } from 'react-loader-spinner'

const HomeUploadFiles = () => {
    const [isLoadingSpinner, setIsLoadingSpinner] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [listFiles, setListFiles] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const [searchResult, setSearchResults] = useState([])
    const dataTable = searchResult.length > 0 ? searchResult : listFiles
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [openModalAddFile, setOpenModalAddFile] = useState(false);
    const [openModalSearchFile, setOpenModalSearchFile] = useState(false);

    const [fileUpload, setFileUpload] = useState([]);

    const [openPopover, setOpenPopover] = useState(false);

    const handleOpenChangePopover = (newOpen) => {
        setOpenPopover(newOpen);
    };

    const showModalAddFile = () => {
        setOpenModalAddFile(true);
    };
    const showModalSearchFile = () => {
        setOpenModalSearchFile(true);
    };
    const handleCancel = () => {
        setOpenModalAddFile(false);
        setOpenModalSearchFile(false)
    };

    const getMimeTypeIcon = (mimeType) => {
        let icon = null;

        if (mimeType === 'image/jpeg' || mimeType === 'image/png') {
            icon = (
                <PictureOutlined style={{ fontSize: '24px', color: 'red' }} />
            );
        } else {
            switch (mimeType) {
                case 'application/pdf':
                    icon = (
                        <FilePdfOutlined style={{ fontSize: '24px', color: '#EA4335' }} />
                    );
                    break;
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                    icon = <FileWordTwoTone style={{ fontSize: '24px' }} />;
                    break;
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    icon = (
                        <FileExcelOutlined style={{ fontSize: '24px', color: '#0F9D58' }} />
                    );
                    break;
                case 'application/x-msdownload':
                    icon = (
                        <WindowsOutlined style={{ fontSize: '24px', color: 'yellow' }} />
                    );
                    break;
                case 'application/x-rar':
                    icon = <FileZipOutlined style={{ fontSize: '24px' }} />;
                    break;
                case 'application/x-zip-compressed':
                    icon = <FileZipOutlined style={{ fontSize: '24px' }} />;
                    break;
                case 'text/plain':
                    icon = (
                        <FileTextTwoTone style={{ fontSize: '24px', color: '#1A73E8' }} />
                    );
                    break;
                case 'application/msword':
                    icon = <FileWordTwoTone style={{ fontSize: '24px' }} />;
                    break;
                case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                    icon = (
                        <FilePptOutlined style={{ fontSize: '24px', color: '#FD7541' }} />
                    );
                    break;
                case 'application/msaccess':
                    icon = <FileSyncOutlined style={{ fontSize: '24px' }} />;
                    break;
                default:
                    icon = <FileUnknownOutlined style={{ fontSize: '24px' }} />;
            }
        }
        return icon;
    };

    const columns = [
        {
            title: 'Tên',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <span>
                    <span style={{ marginRight: '8px' }}>
                        {getMimeTypeIcon(record.mimeType)}
                    </span>
                    <span style={{ fontSize: '15px' }}>{text}</span>
                </span>
            ),
        },
        {
            title: 'Kích thước',
            dataIndex: 'size',
            key: 'size',
            render: (text, record) => `${Math.round(record.size / 1000000 * 100) / 100} MB`,
            width: '10%'
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'createdTime',
            key: 'createdTime',
            render: (text, record) => (<span>{dayjs(text).toISOString().substring(0, 10).split("-").reverse().join("/")}</span>),
            width: '10%'
        },
        // {
        //     title: 'Thumbnail',
        //     key: 'thumbnail',
        //     render: (text, record) => (
        //         record.thumbnailLink ? <img
        //             height={50} width={50}
        //             src={record.thumbnailLink}
        //             alt={record.name} /> : null
        //     ),
        // },
        {
            title: 'Tải xuống',
            key: 'viewLink',
            render: (text, record) => (
                <span style={{ marginLeft: '19px' }}>
                    <a href={record.webViewLink} target="_blank" rel="noopener noreferrer">
                        <CloudDownloadOutlined style={{ fontSize: '24px' }} />
                    </a>
                </span>
            ),
            width: '10%'
        },
    ];

    useEffect(() => {
        if (searchResult.length > 0) {
            handleSearch()
        } else {
            getAllFiles()
        }
    }, [])

    const getAllFiles = async () => {
        let res = await fetchAllFileWithPagin()
        console.log('>>>>>>>> res.DT ', res.DT)
        if (res && +res.EC === 0) {
            setIsLoadingSpinner(true)
            setListFiles(res.DT)
            setIsLoadingSpinner(false)
        } else {
            toast.error(res.EM)
        }
    }

    const handleDelete = async () => {
        setLoadingDelete(true);
        //console.log('>>>>>check id file>>>>', selectedRowKeys)
        let response = await deleteFile(selectedRowKeys)
        //console.log('check user delete >>>>>>>', response)
        if (response && response.EC === 0) {
            toast.success(response.EM)
            setLoadingDelete(false)
            if (searchResult.length > 0) {
                handleSearch()
            } else {
                getAllFiles()
            }
        } else {
            setLoadingDelete(false);
            toast.success(response.EM)
        }
        setSelectedRowKeys([])
    };



    const onSelectChange = (newSelectedRowKeys) => {
        //console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const handleSearch = async () => {
        //console.log('>>>check search >>', searchValue)
        let response = await searchFile(searchValue)
        if (response && response.EC === 0) {
            if (response.DT.length > 0) {
                setSearchResults(response.DT)
                setOpenModalSearchFile(false)
                //console.log('>>SSSS>>', response.DT)
            } else {
                toast.error('Dữ liệu không tồn tại !')
            }

        } else {
            toast.error(response.EM)
        }
    }

    const handleRefresh = () => {
        setSearchResults([])
        setSearchValue('')
        setSelectedRowKeys([])
        //setFile('')
        getAllFiles()
    }

    const handleUpload = async (e) => {
        console.log('>>>fileUpload>>>', fileUpload)
        if (fileUpload) {
            e.preventDefault()
            const formData = new FormData()
            fileUpload.forEach((file) => {
                formData.append('fileUpload', file);
            });
            setLoading(true);
            await uploadFile(formData)
            setLoading(false);
            setOpenModalAddFile(false)
            setSearchResults([])
            setFileUpload([])
            getAllFiles()
        } else {
            toast.error('Hãy chọn lại file để tải lên !')
        }

    }

    // const handleUploadFileChange = (info) => {
    //     console.log('>>>check info> ', info)
    //     console.log('>>>check info.fileList info> ', info.fileList)
    //     let newFileList = [...info.fileList];
    //     newFileList = newFileList.slice(-5);
    //     setFileUpload(newFileList);
    // };

    const props = {
        onRemove: (file) => {
            const index = fileUpload.indexOf(file);
            const newFileList = fileUpload.slice();
            newFileList.splice(index, 1);
            setFileUpload(newFileList);
        },
        beforeUpload: (file) => {
            setFileUpload([...fileUpload, file]);
            return false;
        },
        //onChange: handleUploadFileChange,
        fileList: fileUpload,
        multiple: true,
    };

    return (
        <>
            {isLoadingSpinner === true ? <div className="loading-container">
                <Bars
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
                <div className="loading-text">Đang tải dữ liệu ...</div>
            </div> : <div className='home-uplpoad-container container-fluid my-5'>
                <div className='bt-content d-flex justify-content-between'>
                    <div className='d-flex'>
                        <div className='d-xl-block d-none' style={{ marginRight: 10 }}>
                            <Button
                                className='btn-secondary'
                                type="dashed"
                                size={'large'}
                                onClick={() => handleRefresh()}>
                                <i className="fa fa-refresh" style={{ marginRight: 5 }} />Tải lại trang
                            </Button>
                        </div>
                        <div className='d-xl-none d-block' style={{ marginRight: 10 }}>
                            <Button
                                className='btn-secondary'
                                type="dashed"
                                size={'large'}
                                onClick={() => handleRefresh()}>
                                <i className="fa fa-refresh" />
                            </Button>
                        </div>
                        <div>
                            <div className='d-xl-block d-none'>
                                <Popover
                                    placement="right"
                                    content={hasSelected ? `${selectedRowKeys.length} file đã chọn ` : ''}
                                    trigger="click"
                                    open={hasSelected ? true : false}
                                    onOpenChange={handleOpenChangePopover}
                                >
                                    <Button
                                        danger
                                        type="primary"
                                        size={'large'}
                                        onClick={handleDelete}
                                        disabled={!hasSelected}>
                                        {loadingDelete ? <SyncOutlined spin
                                            style={{ fontSize: '16px' }} />
                                            : <><i className="fa fa-trash-o" style={{ marginRight: 5 }} />Xóa file</>}
                                    </Button>
                                </Popover>
                            </div>
                            <div className='d-xl-none d-block'>
                                <Popover
                                    placement="right"
                                    content={hasSelected ? `${selectedRowKeys.length} file đã chọn ` : ''}
                                    trigger="click"
                                    open={hasSelected ? true : false}
                                    onOpenChange={handleOpenChangePopover}
                                >
                                    <Button
                                        danger
                                        type="primary"
                                        size={'large'}
                                        onClick={handleDelete}
                                        disabled={!hasSelected}>
                                        {loadingDelete ? <SyncOutlined spin
                                            style={{ fontSize: '16px' }} />
                                            : <i className="fa fa-trash-o" />}
                                    </Button>
                                </Popover>
                            </div>

                        </div>
                    </div>

                    <div className='d-flex'>
                        <div>
                            <div className='d-xl-block d-none'>
                                <Button
                                    size={'large'}
                                    className='btn-primary'
                                    onClick={showModalAddFile}
                                ><i className="fa fa-plus-circle" style={{ marginRight: 5 }} />Thêm file</Button>
                            </div>
                            <div className='d-xl-none d-block'>
                                <Button
                                    size={'large'}
                                    className='btn-primary'
                                    onClick={showModalAddFile}
                                ><i className="fa fa-plus-circle" /></Button>
                            </div>
                            <Modal
                                style={{ top: 20 }}
                                title="Thêm File"
                                open={openModalAddFile}
                                onOk={(e) => handleUpload(e)}
                                onCancel={handleCancel}
                                footer={[
                                    <Button key="back" onClick={handleCancel}>
                                        Đóng
                                    </Button>,
                                    <Button
                                        key='link'
                                        shape="circle"
                                        type="primary"
                                        disabled={fileUpload.length === 0}
                                        onClick={(e) => handleUpload(e)}
                                        loading={loading}
                                        icon={<CloudUploadOutlined style={{ fontSize: '17px' }} />}
                                    />
                                ]}
                            >
                                <Upload {...props}>
                                    <Button icon={<UploadOutlined />}>Select File</Button>
                                </Upload>
                            </Modal>
                        </div>

                        <div style={{ marginLeft: 10 }}>
                            <div className='d-xl-block d-none'>
                                <Button
                                    size={'large'}
                                    className='btn-primary'
                                    onClick={showModalSearchFile}
                                ><i className="fa fa-search" style={{ marginRight: 5 }} />Tìm kiếm</Button>
                            </div>
                            <div className='d-xl-none d-block'>
                                <Button
                                    size={'large'}
                                    className='btn-primary'
                                    onClick={showModalSearchFile}
                                ><i className="fa fa-search" /></Button>
                            </div>
                            <Modal
                                style={{ top: 20 }}
                                title="Tìm kiếm"
                                open={openModalSearchFile}
                                onOk={(e) => handleSearch(e)}
                                onCancel={handleCancel}
                                footer={[
                                    <Button key="back" onClick={handleCancel}>
                                        Đóng
                                    </Button>,
                                    <Button
                                        key='link'
                                        shape="circle"
                                        disabled={searchValue.length === 0}
                                        type="primary"
                                        onClick={(e) => handleSearch(e)}
                                        loading={loading}
                                        icon={<i className="fa fa-search" />}
                                    />
                                ]}
                            >
                                <p>
                                    <input className='form-control'
                                        type='text' name='searchValue'
                                        placeholder='Nhập vào tên file'
                                        value={searchValue}
                                        onChange={(e) => setSearchValue(e.target.value)} />
                                </p>
                            </Modal>
                        </div>
                    </div>

                </div>

                <hr className='hr' />

                <div className='Container-home-upload'>

                    <div className='table-container'>
                        <Table
                            rowKey="id"
                            rowSelection={rowSelection}
                            columns={columns}
                            dataSource={dataTable}
                            pagination={{ position: ['bottomCenter'] }}
                            className='table-antd'
                        />
                    </div>
                </div>
            </div>
            }
        </>
    )
}

export default HomeUploadFiles