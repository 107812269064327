import { toast } from 'react-toastify';
import axios from '../setup/axios'

const fetchAllFileWithPagin = () => {
    return axios.get(`/api/v1/project/files`)
}

const searchFile = (key) => {
    //console.log('Check', key)
    return axios.get(`/api/v1/project/file-search?key=${key}`)
}

const uploadFile = async (dataFile) => {
    return axios.post(`/api/v1/project/file-upload`, dataFile,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }
    ).then((res) => {
        //console.log("Success ", res);
        if (res && res.EC === 0) {
            toast.success(res.EM)
        } else {
            toast.success(res.EM)
        }
    });
}

const deleteFile = (fileID) => {
    return axios.delete('/api/v1/project/file-delete', {
        data: { id: fileID }
    })
}


export {
    uploadFile,
    deleteFile,
    fetchAllFileWithPagin,
    searchFile
}