import './Home.scss'
import amduongLogo from '../../assets/images/amduongLogo.png'

const Home = () => {

    return (
        <>
            <div className='home-container'>
                <div className='title1 d-xl-block d-none'>PHÒNG KHÁM ĐÔNG Y</div>
                <div className='title-mobi d-xl-none d-block'>PHÒNG KHÁM ĐÔNG Y</div>
                <div className='logo d-xl-block d-none'>
                    <img
                        alt='logo'
                        src={amduongLogo} width="200" height="200"
                        className="d-inline-block align-top"
                    />
                </div>
                <div className='logo-mobi d-xl-none d-block'>
                    <img
                        alt='logo'
                        src={amduongLogo} width="150" height="150"
                        className="d-inline-block align-top"
                    />
                </div>
                <div className='title2'>
                    <div className='sub-title1 d-xl-block d-none'>LƯƠNG Y ĐA KHOA</div>
                    <div className='sub-title1-mobi d-xl-none d-block'>LƯƠNG Y ĐA KHOA</div>
                    <div className='sub-title2 d-xl-block d-none'>
                        <div className="glitch-wrapper">
                            <div className="glitch" data-glitch="Đoàn Văn Thanh">Đoàn Văn Thanh</div>
                        </div>
                    </div>
                    <div className='sub-title2-mobi d-xl-none d-block'>
                        <div className="glitch-wrapper">
                            <div className="glitch" data-glitch="Đoàn Văn Thanh">Đoàn Văn Thanh</div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Home